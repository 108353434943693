import { Dialog, DialogContent } from '@mui/material';
import {
  useDispatch,
  useSelector
} from '@/services/hooks';
import { closeTimesaleDialog } from '@/services/actions/commonActions';
import TimesaleBanner from '@/components/Banner/TimesaleBanner';

const TimesaleBannerDialog = () => {
  const dispatch = useDispatch();
  const { timesale_dialog } = useSelector(state => state.common);
  const onClose = () => {
    dispatch(closeTimesaleDialog());
  }
  return (
    <Dialog
      open={timesale_dialog.open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 'calc(100% - 20px)',
          maxWidth: '360px',
          margin: 0,
          padding: 0,
        }
      }}
    >
      <DialogContent
        sx={{
          width: '100%',
          padding: 0,
          margin: 0,
        }}
      >
        <TimesaleBanner />
      </DialogContent>
    </Dialog>
  );
};

export default TimesaleBannerDialog;
