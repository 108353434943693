import { Typography } from "@mui/material";

type PropType = {
  duration?: number
}

const Duration = (props: PropType) => {
  const { duration } = props;
  if (!duration) {
    return null;
  }
  // 分と秒を計算
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  const str = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  return (
    <Typography
      color="#FFFFFF"
      sx={{
        fontSize: '13px',
        fontFamily: 'Roboto',
        position: 'absolute',
        bottom: '6px',
        right: '6px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '2px 6px',
        lineHeight: '1.3',
        borderRadius: '40px',
        opacity: 0.75,
      }}
    >
      {str}
    </Typography>
  )
}

export default Duration