import React, { useState, useEffect, ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Button,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import { FormattedMessage } from "react-intl";

export default function PasswordRecovery() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const [serverError, setServerError] = useState<string | ReactElement>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);
  const [isPasswordValid1, setIsPasswordValid1] = useState<boolean>(false);
  const [isPasswordValid2, setIsPasswordValid2] = useState<boolean>(false);

  const navigateTo = useNavigate();

  const validatePassword = (password: string): boolean => {
    if (password.length < 8) {
      setServerError(<FormattedMessage id="user.password_recovery.length_error" />);
      return false
    } else {
      setServerError("");
      return true
    }
  };

  const handlePasswordChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword1(value);
    setIsPasswordValid1(validatePassword(value));
  };
  const handlePasswordChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword2(value);
    setIsPasswordValid2(validatePassword(value));
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setServerError("");

    if (password1 !== password2) {
      setServerError(<FormattedMessage id="user.password_recovery.invalid_password" />);
      setIsSubmitting(false);
      return
    } else {
      setServerError("");
      if (!token) {
        setServerError(<FormattedMessage id="user.password_recovery.invalid_token" />);
        navigateTo("/users/login");
      } else {
        ApiService.updatePassword(token, password1)
          .then(() => navigateTo("/users/login?recovery=true"))
          .catch(() => setServerError(<FormattedMessage id="user.password_recovery.expired" />));
      }
    }

    setIsSubmitting(false);

  };

  useEffect(() => {
    if (!token) {
      setServerError(<FormattedMessage id="user.password_recovery.invalid_token" />);
      navigateTo("/users/login");
    } else {
      console.log("try verify token: ", token);
      ApiService.verify(token)
        .catch(() => setServerError(<FormattedMessage id="user.password_recovery.expired" />));
    }
  }, []);

  const isPasswordsValid = isPasswordValid1 && isPasswordValid2;

  return (
    <AppBar title={<FormattedMessage id="user.password_recovery.title" />}>

      <p><FormattedMessage id="user.password_recovery.description" /></p>

      <Stack spacing={2} margin={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password1">
            <FormattedMessage id="user.password_recovery.password_label1" />
          </InputLabel>{" "}
          <OutlinedInput
            id="outlined-adornment-password1"
            label={<FormattedMessage id="user.password_recovery.password_label1" />}
            type={showPassword1 ? "text" : "password"}
            value={password1}
            onChange={handlePasswordChange1}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility1}
                  edge="end"
                >
                  {showPassword1 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password2">
            <FormattedMessage id="user.password_recovery.password_label2" />
          </InputLabel>{" "}
          <OutlinedInput
            id="outlined-adornment-password2"
            label={<FormattedMessage id="user.password_recovery.password_label2" />}
            type={showPassword2 ? "text" : "password"}
            value={password2}
            onChange={handlePasswordChange2}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility2}
                  edge="end"
                >
                  {showPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        {serverError && <p style={{ color: "#d32f2f" }}>{serverError}</p>}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isPasswordsValid || isSubmitting}
        >
          <FormattedMessage id="user.password_recovery.save" />
        </Button>
      </Stack>
    </AppBar>
  );
}

