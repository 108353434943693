import { Item, validateUserName } from "@/services/models"
import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "@/services/hooks";
import { clickPaymentItem } from "@/techcoin";
import { ApiService } from "@/services/api";
import { openGrandpayInfoInputDialog, openSnackbar } from "@/services/actions/commonActions.ts";
import { useMemo, useState } from "react";
import _ from "lodash";
import { ControlPointDuplicate } from "@mui/icons-material";

type PropTypes = {
  items: Item[]
}

const NormalItemContent = ({ items }: PropTypes) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const normalItems = useMemo(() => {
    return _.chain(items)
      .filter((item) => {
        return !item.purchase_limit && !!item.price;
      })
      .sort((a, b) => a.max_swap - b.max_swap)
      .value()
  }, [items]);

  if (!normalItems) {
    return null
  }

  const handleItemChange = async (item: Item) => {
    if (!user?.id) {
      return;
    }

    if (user?.email === null) {
      dispatch(openSnackbar(<FormattedMessage id="mypage.register_mail.attention" />));
      return;
    }

    setLoading(true);

    try {
      if (item.payment_service_type === 'techcoin') {
        const preorder = await ApiService.createTCPreorder({
          item_id: item.id,
          plan_id: null,
          temporary_id: null,
        });
        clickPaymentItem({
          user_id: user.id,
          email: user.email,
          price: preorder.amount_yen,
          item_id: item.id,
          order_num: preorder.id,
        });
        return;
      }
      if (item.payment_service_type === 'grandpay') {
        if (!user.name || !user.phone || !validateUserName(user.name)) {
          dispatch(openGrandpayInfoInputDialog({ item_id: item.id }));
          setLoading(false);
          return;
        }
        const url = await ApiService.startGrandpayItem({
          item_id: item.id,
          temporary_id: null,
        })
        window.location.href = url;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Stack
      display="flex"
      gap={2}
      px={2}
      py={3}
    >
      <Typography variant="subtitle1">
        <FormattedMessage id="user.plan.item.can_buy_additional_point" />
      </Typography>

      <Stack gap={1.5}>
        {normalItems.map((item) => {
          return (
            <>
              <Divider />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <Box>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    flexShrink={0}
                  >
                    <ControlPointDuplicate color='tertiary' />
                    <Typography
                      fontFamily="Roboto"
                      fontSize="24px"
                      fontWeight={600}
                    >
                      {item.max_swap.toLocaleString()}
                    </Typography>
                    <Typography>
                      <FormattedMessage id="user.plan.swap.unit" />
                    </Typography>
                  </Stack>
                  {item.payment_service_type === 'techcoin' && (
                    <Typography fontSize="12px" fontWeight={700} sx={{ color: 'rgba(255, 0, 0, 0.6)' }}>
                      システム障害により決済停止中です
                    </Typography>
                  )}
                </Box>
                <Button
                  variant="contained"
                  color="tertiary"
                  onClick={() => handleItemChange(item)}
                  sx={{ minWidth: '112px', justifyContent: 'center', flexGrow: 0, gap: "3px", px: "4px" }}
                  disabled={item.payment_service_type === 'techcoin' || loading}
                >
                  ${Math.round(Number(item.price))}
                  <Typography fontSize="12px" color="inherit">
                    <FormattedMessage id="user.plan.item.buy_normal_button" />
                  </Typography>
                </Button>
              </Stack>
            </>
          )
        })}
        <Divider />
      </Stack>
      <Typography variant="caption" sx={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.6)' }}>
        <FormattedMessage id="user.plan.common.button_caption" />
      </Typography>
    </Stack >
  );
}

export default NormalItemContent
