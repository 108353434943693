import _ from 'lodash';
import { closeSwitchImageDialog, openAlertModal, openSnackbar, setProgressPolling } from "@/services/actions/commonActions";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks";
import { Avatar, Box, Button, CardHeader, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { backToMypagePath, backToPlanPagePath, drawerWidth } from '@/constants';
import { useEffect, useMemo, useState } from 'react';
import { color } from '@/services/themes';
import TagManager from 'react-gtm-module';
import { ApiService } from '@/services/api';
import { startProgressSession } from '@/services/progress';
import { getUserDetail } from '@/services/actions/userActions';
import { getRelativeTime } from '@/utils';
import LightStreamPlayer from '@/components/LightStreamPlayer/LightStreamPlayer';
import { Item } from '@/services/models';
// import RevivalPaymentBanner from '@/components/Banner/RevivalPaymentBanner';


export default function SwitchImageDialog() {
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const { user, detail } = useSelector((state) => state.user);
  const remain = _.get(detail, 'swap.item_remain', 0) + _.get(detail, 'swap.plan_remain', 0)

  const { switch_image_dialog } = useSelector((state) => state.common);
  const history = switch_image_dialog.swapped_image_history;
  const scene = history?.original_scene;
  const character = history?.character;
  const usePoint = scene?.point || 0;
  const isSwitchTutorial = scene?.is_tutorial;
  const isLackPoint = remain < usePoint && !isSwitchTutorial;
  const disabled = !character?.id || isLackPoint || loading;
  const [items, setItems] = useState<Item[]>([]);

  const closeDialog = () => {
    dispatch(closeSwitchImageDialog());
  };

  useEffect(() => {
    if (!switch_image_dialog.open) {
      return;
    }
    dispatch(getUserDetail())
  }, [switch_image_dialog.open]);

  useEffect(() => {
    ApiService.fetchItems().then((data) => {
      setItems(data);
    })
  }, [user])

  const appealButton = useMemo(() => {
    if (!isLackPoint) {
      return null;
    }
    if (_.some(items, (item) => {
      return item.purchase_limit === 1 && item.price === '2.00';
    })) {
      return (
        <Button
          variant="contained"
          color="tertiary"
          onClick={() => {
            closeDialog();
            navigateTo(backToMypagePath)
          }}
        >
          <FormattedMessage id="switch.dialog.button.limited_item_1" />
          （{<FormattedMessage id="switch.dialog.button.limited_item_2" />}
          &nbsp;
          <Box display="inline" sx={{ color: '#FFF78C' }}><Box display="inline" sx={{ textDecoration: 'line-through' }}>$7</Box> ⇒$2</Box>
          ）
        </Button>
      )
    }
    if (user?.is_free_plan) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            closeDialog();
            navigateTo(backToPlanPagePath)
          }}
        >
          <FormattedMessage id="switch.dialog.button.upgrade" />
        </Button>
      )
    }
    return (
      <Button
        variant="contained"
        color="tertiary"
        onClick={() => {
          closeDialog();
          navigateTo(backToMypagePath)
        }}
      >
        <FormattedMessage id="switch.dialog.button.item" />
      </Button>
    )
  }, [user, items, detail, switch_image_dialog, isLackPoint]);

  const submitGTM = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "swap",
        user_id: user?.id,
        is_free_plan: user?.is_free_plan,
        scene_id: scene?.id,
        is_tutorial: scene?.is_tutorial,
      }
    });
  }

  const handleSubmit = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const response = await ApiService.generate(Number(character?.id), scene?.id as number);
      if (!response.success) {
        const errorMessage = formatMessage({ id: `generate.error.${response.error_type}`, defaultMessage: "Error" });
        // 特定のエラーの場合はモーダルで表示
        if (response.error_type === 'over_parallel_limit') {
          dispatch(openAlertModal(errorMessage));
        } else {
          dispatch(openSnackbar(errorMessage));
        }
      } else {
        closeDialog();
        submitGTM()
        dispatch(setProgressPolling(true));
        startProgressSession(); // sessionに開始したことを保存
        dispatch(getUserDetail());
        navigateTo(`/?switched=true`);
        // navigateTo('/?tab=switched');
      }
    } catch (error: unknown) {
      console.log("generated error: ", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      onClose={closeDialog}
      open={switch_image_dialog.open}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: '343px',
          width: '100%',
          margin: '16px',
          padding: '8px',
        },
        "& .MuiDialog-container": {
          sm: { marginLeft: `${drawerWidth}px` }
        },
      }}
    >
      <DialogContent
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          padding: 0,
        }}
      >
        <Stack gap={1} p={1}>
          <CardHeader
            sx={{ padding: 0 }}
            avatar={
              <Avatar
                src={history?.character?.url}
                sx={{ width: 61, height: 61 }}
              />
            }
            title={history?.character?.name}
            {...{ subheader: getRelativeTime(history?.created_at as string) }}
          />

          <LightStreamPlayer
            is_tutorial={scene?.is_tutorial}
            scene_id={scene?.id}
            src={scene?.uid as string}
            is_limited={character?.is_limited}
            thumbnail_start_sec={scene?.thumbnail_start_sec}
            scene_type="switched_image"
          />

          <Box
            p={2}
            sx={{
              backgroundColor: '#F2F2F2',
            }}
          >
            <Typography
              variant="subtitle1"
              lineHeight={1}
              textAlign="center"
            >
              <FormattedMessage id="switch.dialog.remain_point" />{"："}
              <Box
                sx={{
                  color: color.main,
                  fontFamily: 'Roboto',
                  display: 'inline'
                }}
              >
                {remain}
              </Box>
            </Typography>
          </Box>

          <Typography variant="body1">
            <FormattedMessage id="switch.dialog.use_point" />{"："}{scene?.point}
          </Typography>

          <Stack gap={1}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={disabled}
              onClick={handleSubmit}
            >
              <FormattedMessage id="switch_image.dialog.switch_button" />
            </Button>
            <Typography variant="caption">
              <FormattedMessage id="switch.dialig.switch.caution" />
            </Typography>
            {appealButton}

            {/* <RevivalPaymentBanner onClick={closeDialog} /> */}

            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{
                mt: '8px',
              }}
              onClick={() => {
                closeDialog();
              }}
            >
              <FormattedMessage id="cancel" />
            </Button>
          </Stack>
        </Stack>

      </DialogContent>
    </Dialog>
  );
}