//export const MEDIA_ACCEPTS = "image/*,video/mp4,image/gif";
export const MEDIA_ACCEPTS = "video/mp4";

export const drawerWidth = 240;
export const appBarHeight = 64;
export const appBarMiniHeight = 56;
export const timesalesCountdownHeight = 40;
export const isAdult = window.location.pathname.indexOf('/normal') !== 0;
export const cfCustomerCode = 'oley77jhqtu7sxpf'

// export const backToMypagePath = '/mypage';
export const backToMypagePath = '/users/plan';
export const backToPlanPagePath = '/users/plan';