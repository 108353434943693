import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { mainTheme } from "@/services/themes";
import { Scene } from "@/services/models";
import DummyPlayer from "@/components/LightStreamPlayer/DummyPlayer";
import Genre from '@/components/Scene/gunre';

interface Props {
  showDivider: boolean;
  scene: Scene;
  onClick: () => void;
}

export default function SceneDummyComp({
  showDivider,
  scene,
  onClick,
}: Props) {
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          position: 'relative',
        }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          marginTop={2}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          {scene.title}
        </Typography>

        <Genre scene={scene} />

        <Swiper
          modules={[Navigation, Pagination]}
          pagination
          navigation
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "--swiper-navigation-size": "20px",
            "--swiper-theme-color": mainTheme.palette.primary.main,
            marginTop: '16px'
          }}
          key={scene.id}
        >
          <SwiperSlide style={{ padding: 0 }}>
            <DummyPlayer src={scene.uid} thumbnail_start_sec={scene.thumbnail_start_sec} onClick={onClick} />
          </SwiperSlide>
        </Swiper>
      </Card>
      {showDivider && <Divider sx={{ mt: 2 }} />}
    </>
  );
}
