import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ApiService } from "@/services/api.ts";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks.ts";
import { openSnackbar } from "@/services/actions/commonActions.ts";
import { FormattedMessage } from "react-intl";
import { initTutorialStep } from '@/services/tutorial';
import TagManager from "react-gtm-module";
import { getCookie } from "@/utils.ts";

const LineSignup: React.FC = () => {
  const navigate = useNavigate();
  const { referrer } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const [isLoaded, setIsLoaded] = useState(false);
  const bncDspKey = import.meta.env.VITE_DSP_BNC;

  useEffect(() => {
    // 2回ロードされてユーザー作成成功しているのにログインされないことがあるため、1回しか通らないように制御
    if (isLoaded) {
      return;
    }
    const handleLineCallback = async () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "redirect_line_signup",
        }
      });
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      // BANCE DSP広告用
      const bncClickId = getCookie(bncDspKey);
      const dspParam: { [key: string]: string } = {};
      if (bncClickId) {
        dspParam.bnc = bncClickId;
      }

      if (!code) {
        console.error('No authorization code returned from LINE');
        navigateTo("/users/signup");
        dispatch(openSnackbar(<FormattedMessage id="user.signup.already_signup" />));
        return;
      }

      try {
        const data = await ApiService.registerWithLine({
          code,
          referrer,
          dsp_param: Object.keys(dspParam).length > 0 ? dspParam : undefined,
        });

        console.log("user registration: ", data);
        TagManager.dataLayer({
          dataLayer: {
            event: "signup",
            signup_type: "line",
          }
        });
        initTutorialStep();
        // tutorialの読み込みのためにnavigateToではなく window.location.href の変更にした
        setIsLoaded(true);
        window.location.href = '/?login=true';
      } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 409) {
          navigateTo("/users/signup?social_login=false");
          return;
        } else {
          // TODO: signup成功しても通ってしまう
          // dispatch(openSnackbar(<FormattedMessage id="user.signup.social.error" />));
        }
        navigateTo("/users/signup");
      }
    };

    handleLineCallback();
  }, [navigate]);

  return '';
};

export default LineSignup;
