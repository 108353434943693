import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { color } from "@/services/themes";

import AppBar from "@/components/AppBar";
import CharacterList from '@/components/CharacterList';
import { openSnackbar } from "@/services/actions/commonActions";
import { useDispatch, useSelector, useUrlParams } from '@/services/hooks';
import { isAdult } from "@/constants";
import { FormattedMessage } from "react-intl";
import { TUTORIAL_STEP } from "@/services/tutorial";
import Mask from "@/components/common/Mask";
import SwitchViewHint from "@/components/common/Hint/SwitchViewHint";
import { ImportantNotice } from "@/components/common/ImportantNotice";

import GenreSearch from "@/pages/Home/GenreSearch";
import Banner from "@/pages/Home/Banner";
import Recommend from "@/pages/Home/Recommend";
import Favorite from "@/pages/Home/Favorite";
import Switched from "@/pages/Home/Switched";
import TagManager from "react-gtm-module";
import { getUnplayedSwitchedSceneCount } from "@/services/actions/sceneActions";
import ConditionalDisplay from "@/components/common/ConditionalDisplay";
import { trackingUrlParam } from "@/utils";
import { getCharacters } from "@/services/actions/characterActions";
import { getLoginBonus, getUserDetail } from "@/services/actions/userActions";
import SwitchTab from "@/components/Home/Switched/SwitchTab";
// import OnetimeItemBanner from "@/components/Banner/OnetimeItemBanner";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TAB_RECOMMENDATION = 0
const TAB_SWITCHED = 1
const TAB_FAVORITE = 2

export default function HomeIndex() {
  const { getUrlParam, unsetUrlParam } = useUrlParams();
  const completeToggle = useSelector((state) => state.common.progress.complete_toggle);
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  // const isSwitchTutorial = tutorialStep === TUTORIAL_STEP.switch;

  const isViewTutorial = tutorialStep === TUTORIAL_STEP.view;
  const isNotTutorial = !tutorialStep || tutorialStep === TUTORIAL_STEP.done;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { user, loading, detail } = useSelector((state) => state.user);
  const unplayedCount = useSelector((state) => state.scene.unplayed_count);
  const [tabScrollPosition, setTabScrollPosition] = useState<{ [key: number]: number }>({
    [TAB_RECOMMENDATION]: 0,
    [TAB_SWITCHED]: 0,
    [TAB_FAVORITE]: 0,
  });
  const [switchedTabType, setSwitchedTabType] = useState<'scene' | 'image'>('scene');

  // const canShowSwitchTrial = user && user.is_free_plan && !isSwitchTutorial && !detail?.switch_trial_completed;

  const scrollDialog = useCallback((node: HTMLImageElement) => {
    if (!node) {
      return;
    }
    node.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const tabNumberMapping: { [key: number]: string } = {
    [TAB_RECOMMENDATION]: 'recommend',
    [TAB_SWITCHED]: 'switch',
    [TAB_FAVORITE]: 'favorite',
  }

  const [value, setValue] = useState(TAB_RECOMMENDATION);

  const isSwitchTrial = user && user.is_free_plan && !detail?.switch_trial_completed;
  const isShowGenreSearch = (value === TAB_RECOMMENDATION && isNotTutorial && !isSwitchTrial) || false;

  useEffect(() => {
    dispatch(getLoginBonus());
    // switch trialの判定に使ってる
    dispatch(getUserDetail());
  }, [])

  const tabValue = getUrlParam('tab');
  useEffect(() => {
    if (tabValue === 'switched') {
      setValue(TAB_SWITCHED);
      unsetUrlParam('tab');
    } else if (tabValue === 'favorite') {
      setValue(TAB_FAVORITE);
      unsetUrlParam('tab');
    }
  }, [tabValue]);

  const getUnplayedCount = () => {
    dispatch(getUnplayedSwitchedSceneCount());
  }

  useEffect(() => {
    getUnplayedCount();
  }, [completeToggle])

  // タブが切り替わったら、前回そのタブでスクロールした位置に戻す
  // タブ切り替えののハンドラで実行するとスクロールが効かないので、useEffectで実行
  useEffect(() => {
    window.scrollTo(0, tabScrollPosition[value]);
  }, [value]);

  const handleChange = (_event: React.SyntheticEvent | null, newValue: number) => {
    setValue(newValue);
    setTabScrollPosition((prev) => ({ ...prev, [value]: window.scrollY }));
    TagManager.dataLayer({
      dataLayer: {
        event: "change_home_tab",
        tab_type: tabNumberMapping[newValue] || 'unknown',
        user_id: user?.id,
      }
    });
  };

  const clickSwitchHint = () => {
    handleChange(null, TAB_SWITCHED)
  };

  useEffect(() => {
    if (!loading) {
      if (!user && isAdult) {
        const queryWithHash = trackingUrlParam()
        navigateTo(`/demo${queryWithHash}`);
      }
    }
  }, [loading]);

  useEffect(() => {
    // キャラクター一覧を取得
    dispatch(getCharacters(!isNotTutorial))

    // キャラクター作成後のメッセージ表示
    if (location.state?.createdCharacterId) {
      dispatch(openSnackbar(<FormattedMessage id="character.created" />));
    }

  }, [tutorialStep, user]);

  return (
    <AppBar title={<FormattedMessage id="home.title" />} isPrimary={true}>
      <Stack direction="column" spacing={0} margin={0} marginBottom={2} gap={3} >
        <ImportantNotice />

        {/* バナー */}
        <Banner />

        <Box width="100%" paddingX={2}>
          <CharacterList
            isLogin={!!user}
            createOnClick={(characterId: number) => {
              return () => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "click_character",
                    user_id: user?.id,
                    character_id: characterId,
                    from: "home",
                  }
                });
                navigateTo(`/characters/${characterId}`)
              }
            }}
            closeDialog={() => { }}
          />
        </Box>

        {/* tutorial */}
        <Mask
          open={isViewTutorial} // タブわけの弊害
          zIndex={100}
        />

        <Box
          sx={{
            width: '100%',
            background: "rgba(255, 255, 255, 0.95)",
            zIndex: 100,
            marginTop: -1.25,
            paddingTop: 1.25,
          }}
          position="sticky"
          top={"56px"}
        >
          <Box position="relative" sx={{ borderBottom: 1, borderColor: 'divider' }} paddingLeft={2} paddingRight={2}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="fullWidth" >
              <Tab
                label={<FormattedMessage id="home.recommend" />}
                {...a11yProps(TAB_RECOMMENDATION)}
                sx={{ paddingBottom: '4px', fontSize: '15px', whiteSpace: 'nowrap' }}
                color="text.secondary"
              />
              <Tab
                ref={isViewTutorial ? scrollDialog : undefined}
                label={<FormattedMessage id="home.switched" />}
                {...a11yProps(TAB_SWITCHED)}
                sx={{
                  paddingBottom: '4px',
                  zIndex: isViewTutorial ? 101 : 1,
                  background: isViewTutorial ? 'white' : 'transparent',
                  fontSize: '15px',
                  whiteSpace: 'nowrap',
                }}
                color="text.secondary"
              />
              <Tab
                label={<FormattedMessage id="home.favorite" />}
                {...a11yProps(TAB_FAVORITE)}
                sx={{ paddingBottom: '4px', fontSize: '15px', whiteSpace: 'nowrap' }}
                color="text.secondary"
              />

              {
                unplayedCount > 0 && (
                  <Typography
                    fontFamily="roboto"
                    fontWeight={500}
                    fontSize="11px"
                    sx={{
                      color: '#FFFFFF',
                      background: color.main,
                      display: 'flex',
                      position: 'absolute',
                      height: '16px',
                      width: '16px',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      left: '63%',
                      top: '12px',
                    }}
                  >
                    {unplayedCount}
                  </Typography>
                )
              }
            </Tabs>
            {isViewTutorial && <SwitchViewHint onClick={clickSwitchHint} />}

            {
              !user?.is_free_plan && value === TAB_SWITCHED && (
                <SwitchTab
                  tabType={switchedTabType}
                  setTabType={setSwitchedTabType}
                />
              )
            }
          </Box>
          {/* ジャンル絞り込み */}
          {
            isShowGenreSearch && (
              <Box mt={2}>
                <GenreSearch />
              </Box>
            )
          }
        </Box>
        {/* TODO: 決済が停止されているので表示しない */}
        {/* {
          isNotTutorial && !canShowSwitchTrial && (
            <OnetimeItemBanner />
          )
        } */}
        {/* 各々のタブの出しわけ */}
        <Box width="100%" overflow="hidden">
          <Box display="flex" flexDirection="row" width="300%" sx={{
            marginLeft: `-${value * 100}%`,
            marginRight: `${value * 100}%`,
            transition: 'margin-left 0.3s, margin-right 0.3s',
          }}>
            <Box width="calc(100% / 3)" flex={1}>
              <ConditionalDisplay isShow={value === TAB_RECOMMENDATION} hideDelay={300}>
                <Recommend
                  isShowGenreSearch={isShowGenreSearch}
                />
              </ConditionalDisplay>
            </Box>
            <Box width="calc(100% / 3)" flex={1}>
              <ConditionalDisplay isShow={value === TAB_SWITCHED} hideDelay={300}>
                <Switched
                  tabType={switchedTabType}
                />
              </ConditionalDisplay>
            </Box>
            <Box width="calc(100% / 3)" flex={1}>
              <ConditionalDisplay isShow={value === TAB_FAVORITE} hideDelay={300}>
                <Favorite
                  isShow={value === TAB_FAVORITE}
                />
              </ConditionalDisplay>
            </Box>
          </Box>
        </Box>
      </Stack>
    </AppBar >
  );
}
