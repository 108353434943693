import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

import AppBar from "@/components/AppBar";
import { useDispatch, useSelector } from '@/services/hooks';
import { useEffect, useMemo, /* useState */ } from 'react';
import { getUserDetail } from '@/services/actions/userActions';
import { /* Item, */ Plan, createPurchaseTemporaryId, validateUserName } from '@/services/models';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
// import { useLocation } from 'react-router-dom';
import { mainTheme } from '@/services/themes';
import TagManager from "react-gtm-module";
import CurrentPlan from '@/components/common/CurrentPlan';
import PlanFeatureDescription from '@/components/common/Plan Feature Description';
import AboutSwitchedScene from '@/components/common/AboutSwitchedScene';
// import { clickPaymentPlan } from '@/techcoin';
import { ApiService } from '@/services/api';
import CanBronzePlan from '@/components/common/Plan/CanBronzPlan';
import LimitedTimeCampaign from '@/components/UserPlan/LimitedCampaign';
import { isLimitedCampaignPeriod } from '@/utils';
import { openGrandpayInfoInputDialog, openSnackbar } from "@/services/actions/commonActions.ts";
// import LimitedItemContent from '@/components/MyPage/LimitedItemContent';
import TimesaleBanner from '@/components/Banner/TimesaleBanner';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FREE_PLAN_ID = 1;
const BRONZE_PLAN_ID = 2;

type propType = {
  plans: Plan[];
};

const FreePlan = (props: propType) => {
  const { plans } = props;
  // const location = useLocation();
  const dispatch = useDispatch();
  const showTimesaleCountdown = useSelector((state) => state.common.timesale_countdown.show);
  const { user, detail } = useSelector((state) => state.user);
  const isCampaignTarget = !!(
    (detail?.is_compensation_target && user?.is_free_plan && isLimitedCampaignPeriod()) // 補填対象者
    || (user?.is_free_plan && detail?.has_paid && isLimitedCampaignPeriod()) // 過去に課金経験あり
  )

  const bronzePlan = useMemo(() => {
    return _.find(plans, { id: BRONZE_PLAN_ID });
  }, [plans]);

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch])

  // const [items, setItems] = useState<Item[]>([]);
  // useEffect(() => {
  //   ApiService.fetchItems().then((data) => {
  //     setItems(data);
  //   })
  // }, [])

  // const limitItems = useMemo(() => {
  //   return _.filter(items, (item) => {
  //     return item.purchase_limit === 1 && item.price === '2.00';
  //   })
  // }, [items]);

  const handlePlanChange = async () => {
    if (!bronzePlan?.id || !user?.id) {
      return
    }

    if (user?.email === null) {
      dispatch(openSnackbar(<FormattedMessage id="mypage.register_mail.attention" />));
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "click_buy_plan_button",
        user_id: user.id,
      }
    });

    try {
      const temporaryId = createPurchaseTemporaryId();
      // TechCoinが停止中なのでGrandPayを使う
      // const preorder = await ApiService.createTCPreorder({
      //   item_id: null,
      //   plan_id: bronzePlan.id,
      //   temporary_id: temporaryId,
      // });

      // clickPaymentPlan({
      //   user_id: user.id,
      //   email: user.email,
      //   price: preorder.amount_yen,
      //   plan_id: bronzePlan.id,
      //   plan_name: bronzePlan.name,
      //   order_num: preorder.id,
      // });
      if (!user.name || !user.phone || !validateUserName(user.name)) {
        dispatch(openGrandpayInfoInputDialog({ plan_id: bronzePlan.id }));
        return;
      }
      const url = await ApiService.startGrandpayPlan({
        plan_id: bronzePlan.id,
        temporary_id: temporaryId,
      })
      window.location.href = url;
    } catch (e) {
      console.log(e);
    }

  };

  const renderBuyBronzeButton = () => {
    if (!bronzePlan) {
      return null;
    }
    return (
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        display="flex"
        width="100%"
        paddingBottom={3}
      >
        {isCampaignTarget ? (<>
          {/* TODO: 決済復活キャンペーン終了したら消す */}
          <Typography color="primary" fontWeight={500} fontSize="16px">
            ＼こちらからキャンペーンに参加／
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              marginTop: '8px !important',
            }}
            onClick={handlePlanChange}
          >
            {bronzePlan.name}プランに再登録
          </Button>
          {/* ここまで */}
        </>) : (<>
          <Typography variant="caption" sx={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.6)' }}>
            <FormattedMessage id="user.plan.common.button_caption" />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              marginTop: '8px !important',
            }}
            onClick={handlePlanChange}
          >
            {bronzePlan.name}<FormattedMessage id="user.plan.buy_plan" /> - ${bronzePlan.price.toLocaleString()}<FormattedMessage id="user.plan.per_month" />
          </Button>
        </>)}
      </Stack>

    );
  }

  const RewardDetails = () => {
    return (
      <div>
        {/* トグル - もらえる報酬 */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">報酬付与について</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li>もらえる報酬</li>
                <ul>
                  <li>タイムセール中にBronzeプランにサブスク登録すると通常2,000ptのところ3,000ptもらえます</li>
                  <li>Bronzeプラン登録でもらえる通常ポイント：2,000pt</li>
                  <li>タイムセール中に登録でもらえる追加ポイント：1,000pt</li>
                </ul>

                <li>付与タイミング</li>
                <ul>
                  <li>
                    タイムセール中にサブスク登録すると通常ポイントと合わせた合計3,000ptが付与されます
                  </li>
                </ul>
              </ul>
            </Typography>

          </AccordionDetails>
        </Accordion>

        {/* トグル - 報酬条件 */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">報酬条件</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li>タイムセール中(30分以内)にBronzeプランへのサブスク登録を完了する</li>
                <li>おひとりさま1回限り</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* トグル - 注意 */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">注意</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li>このキャンペーンは予告なく変更・中止する可能性がございます</li>
                <li>
                  不正なポイント取得が確認された場合は、アカウント停止やポイント回収を実施する可能性がございます
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  return (
    <AppBar title={<FormattedMessage id="user.plan.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>
        <List sx={{ padding: 0 }}>
          <CurrentPlan
            disableShowPoint
          />
          {showTimesaleCountdown ? (
            <Stack paddingX={2} marginY={2} gap={1}>
              <TimesaleBanner
                disabledButton
              />
              <RewardDetails />
            </Stack>
          ) : null
          }
          {/* TODO: 決済が停止されているので表示しない */}
          {/* <LimitedItemContent
            items={limitItems}
          /> */}
          <Divider />
          {isCampaignTarget && <LimitedTimeCampaign />} {/* TODO: 決済復活キャンペーン終了したら消す */}
          <ListItem
            sx={{
              display: 'flex',
              padding: '24px 16px 0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0',
              alignSelf: 'stretch',
            }}
          >
            <ListItemText
              disableTypography
              primary={<Typography variant="subtitle2" color="rgba(0, 0, 0, 0.87)"><FormattedMessage id="user.plan.plan_list" /></Typography>}
            />
            <List sx={{ padding: 0, width: '100%', marginBottom: '16px', }}>
              {plans.map(plan => {
                if (plan.id === FREE_PLAN_ID) {
                  return null;
                }
                return (
                  <ListItem
                    key={plan.id}
                    sx={{
                      display: 'flex',
                      padding: '16px 0',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      gap: '24px',
                      borderRadius: '4px',
                      border: `2px solid ${mainTheme.palette.primary.main}`,
                      marginTop: '16px',
                      fontFamily: 'Roboto',
                    }}
                  >
                    <Typography sx={{
                      padding: '0',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '24px',
                      fontWeight: 600,
                      fontFamily: 'Roboto',
                    }}>
                      {plan.name}
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          display: 'inline-block',
                          marginLeft: '4px',
                          lineHeight: '133.4%',
                          fontFamily: 'Roboto',
                        }}
                      ><FormattedMessage id="user.plan.plan_suffix" /></Typography>
                    </Typography>
                    <Typography
                      sx={{
                        color: '#000',
                        fontSize: '20px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.4px',
                        fontFamily: 'Roboto',
                        display: 'flex',
                      }}
                    >
                      ${plan.price.toLocaleString()}
                      <Typography variant="body1"><FormattedMessage id="user.plan.per_month" /></Typography>
                    </Typography>
                  </ListItem>
                )
              })}
            </List>

            {renderBuyBronzeButton()}
            <Stack
              spacing={2}
              width="100%"
              padding={2}
              margin="8px 0 16px"
              sx={{
                backgroundColor: '#F2F2F2',
              }}
            >
              <Typography variant="subtitle1">
                <FormattedMessage id="user.plan.free.bronze_can" />
              </Typography>

              <CanBronzePlan />

              <Stack
                spacing={2}
                width="100%"
              >
                <Typography
                  variant="subtitle1"
                  fontSize="18px"
                  textAlign="left"
                >
                  <FormattedMessage id="user.plan.free.point_title" />
                </Typography>

                <Stack
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  gap="20px"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                  >
                    <img style={{ width: '70px' }} src="/plan/Point1.png" srcSet="/plan/Point1@2x.png 2x,/plan/Point1@3x.png 3x" alt="point1" />
                    <Stack display="flex" justifyContent="center">
                      <Typography variant="subtitle1"><FormattedMessage id="user.plan.free.point1_text1" /></Typography>
                      <Typography variant="caption" color="textSecondary" sx={{ fontSize: '13px' }}><FormattedMessage id="user.plan.free.point1_text2" /></Typography>
                    </Stack>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                  >
                    <img style={{ width: '70px' }} src="/plan/Point2.png" srcSet="/plan/Point2@2x.png 2x,/plan/Point2@3x.png 3x" alt="point2" />
                    <Stack display="flex" justifyContent="center">
                      <Typography variant="subtitle1"><FormattedMessage id="user.plan.free.point2_text1" /></Typography>
                      <Typography variant="caption" color="textSecondary" sx={{ fontSize: '13px' }}><FormattedMessage id="user.plan.free.point2_text2" /></Typography>
                    </Stack>
                  </Box>
                  {/* TODO: サブスクが復活したらコメントイン */}
                  {/* <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                  >
                    <img style={{ width: '70px' }} src="/plan/Point3.png" srcSet="/plan/Point3@2x.png 2x,/plan/Point3@3x.png 3x" alt="point3" />
                    <Stack display="flex" justifyContent="center">
                      <Typography variant="subtitle1"><FormattedMessage id="user.plan.free.point3_text1" /></Typography>
                      <Typography variant="caption" color="textSecondary" sx={{ fontSize: '13px' }} > <FormattedMessage id="user.plan.free.point3_text2" /></Typography>
                    </Stack>
                  </Box> */}
                </Stack>
              </Stack>

              {/* 疑似サブスク用のテキスト */}
              <Typography
                variant="subtitle1"
              ><FormattedMessage id="user.plan.free.caution" /></Typography>
              <Typography
                variant="caption"
                sx={{
                  fontSize: '12px',
                  color: 'rgba(216, 27, 96, 1)',
                }}
              >
                <FormattedMessage id="user.plan.free.caution_description" />
              </Typography>

              {/* TODO: サブスクが復活したらコメントイン */}
              {/* <Typography
                variant="subtitle1"
              ><FormattedMessage id="user.plan.free.announce" /></Typography>
              <Typography
                variant="caption"
                sx={{
                  fontSize: '9px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  whiteSpace: 'pre-line',
                  lineHeight: 1,
                }}
              >
                <FormattedMessage id="user.plan.free.announce_description" />
              </Typography> */}

            </Stack>
            {renderBuyBronzeButton()}

            <AboutSwitchedScene />
            <PlanFeatureDescription
              plans={plans}
            />

          </ListItem>
        </List >
      </div >
    </AppBar >
  );
};

export default FreePlan;
