import SmallGenreButton from "@/components/common/SmallGenreButton";
import { useUrlParams } from "@/services/hooks";
import { Scene } from "@/services/models";
import { Box, Typography } from "@mui/material";
import { loadUTC, resolveGenreParam } from "@/utils";

interface Props {
  scene: Scene;
}

const Genre = ({ scene }: Props) => {
  const now = loadUTC()
  const postedAt = loadUTC(scene.posted_at)
  const isNew = now.add(-7, 'day').isBefore(postedAt)
  const isSemiNew = !isNew && now.add(-21, 'day').isBefore(postedAt)

  const { setUrlParam, getUrlParam } = useUrlParams();

  if (!isNew && isSemiNew && !scene.genres?.length) {
    return null;
  }
  return <Box
    padding={0}
    display="flex"
    alignItems="center"
    overflow="scroll"
    gap="6px"
    mt="12px"
  >
    <Typography
      variant="body1"
      fontSize="12px"
      flexShrink={0}
      sx={{
        color: '#000000DE',
        fontWeight: 400,
      }}
    >タグ：</Typography>
    <Box
      display='flex'
      flexWrap='wrap'
      gap="6px"
    >
      {
        isNew && (
          <SmallGenreButton
            key="new"
            label="新作"
            onClick={() => {
              setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), '新作'));
            }}
          />
        )
      }
      {
        isSemiNew && (
          <SmallGenreButton
            key="semiNew"
            label="準新作"
            onClick={() => {
              setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), '準新作'));
            }}
          />
        )
      }
      {
        scene.genres?.map((genre) => {
          return (
            <SmallGenreButton
              key={genre?.id}
              label={genre?.name}
              onClick={() => {
                setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), genre?.name));
              }}
            />
          )
        })
      }
    </Box>
  </Box>
}

export default Genre;