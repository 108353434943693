import { User, UserDetail } from "@/services/models";
import TagManager from "react-gtm-module"

interface UserState {
  user: User | null;
  detail: UserDetail | null;
  loading: boolean;
  error: string | null;
  daily_trial: {
    image_switch_count: number;
    scene_switch_count: number;
    reseted_at?: string;
  };
}

const initialState: UserState = {
  user: null,
  detail: null,
  loading: true,
  error: null,
  daily_trial: {
    image_switch_count: 0,
    scene_switch_count: 0,
  }
};

export const userReducer = (state = initialState, action: any): UserState => {
  console.log("usrReducer action: ", action);
  switch (action.type) {
    case "USER_INFO_REQUEST":
      return { ...state, loading: true };
    case "USER_INFO_SUCCESS": {
      TagManager.dataLayer({
        dataLayer: {
          user_id: action.payload.id,
        }
      })
      return { ...state, user: action.payload, loading: false };
    }
    case "USER_INFO_FAIL":
    case "USER_LOGOUT":
      return { ...state, ...initialState, loading: false };
    case "USER_LOGIN_FAIL":
      return { ...state, user: null, loading: false, error: action.payload };
    case "USER_DETAIL_SUCCESS":
      return { ...state, detail: action.payload };
    case "LOGIN_BONUS_SUCCESS":
      return { ...state, daily_trial: action.payload };
    case "USER_UPDATE_SUCCESS":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
