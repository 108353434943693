export default {
  "recommend": "おすすめ",
  "trending_tags": "人気のタグ",
  "cancel": "キャンセル",
  "free": "無料",
  "close": "閉じる",
  "submit": "送信",
  "change": "変更する",
  "alert_modal.close": "閉じる",
  "hint.switch.how_to_use": "使い方のヒント",
  "hint.switch.lets": "顔をスイッチしてみよう！",
  "hint.switch.caption": "このマークは無料で試せるよ",
  "hint.switch_view.lets": "Switchした動画はここに表示されるよ！",
  "hint.switch_view.caption": "実際に動画がSwitchされるのは少し時間がかかることもあるよ❤️",
  "hint.character.lets": "あなたの好きな顔画像でオリジナルのAVを作ろう！",
  "hint.character.caption": "好きな顔画像をアップロードして、キャラクターを作成すると、オリジナルのAVが作れるようになるよ❤️",
  "hint.close_hint": "ヒントを隠す",
  "header.signup": "新規会員登録（無料）",
  "sidebar.login": "ログイン",
  "sidebar.logged_in": "ログイン中",
  "sidebar.logout": "ログアウト",
  "sidebar.home": "ホーム",
  "sidebar.mypage": "マイページ",
  "sidebar.videos": "見放題動画",
  "sidebar.characters": "マイキャラクター",
  "sidebar.faq": "よくある質問",
  "sidebar.contact": "お問い合わせ",
  "sidebar.notice": "お知らせ",
  "sidebar.terms": "利用規約",
  "sidebar.policy": "プライバシーポリシー",
  "sidebar.law": "特商法に基づく表記",
  "sidebar.other": "その他",
  "sidebar.help": "ヘルプ",
  "notice.title": "お知らせ",
  "notice.view.title": "メッセージ",
  "notice.view.back": "戻る",
  "notice.empty": "お知らせはありません",
  "notice.all_check": "すべて既読",
  "login_bonus.title": "ログインボーナス",
  "login_bonus.title_1": "お試し画像Switch",
  "login_bonus.title_2_a": "毎日",
  "login_bonus.title_2_b": "回まで",
  "login_bonus.title_2_c": "無料",
  "login_bonus.limited": "今だけ限定",
  "login_bonus.text": `毎日ログインして、Switch動画の
出来栄えを画像で確認しよう！`,
  "login_bonus.button": "Switchする動画を探す",
  "switch.dialog.warning": "画像はイメージです。実際の内容と異なる場合があります。",
  "switch.dialog.remain_point": "保有ポイント",
  "switch.dialog.use_point": "消費ポイント",
  "switch.dialog.switch_button": "この顔でSwitchする",
  "switch_image.dialog.switch_button": "Switchする",
  "switch.dialog.button.limited_item_1": "お得にSwitch",
  "switch.dialog.button.limited_item_2": "初回限定",
  "switch.dialog.button.upgrade": "アップグレードしてSwitchする",
  "switch.dialog.button.item": "追加でポイントを購入する",
  "switch.dialig.switch.caution": "※動画/顔が公開されることはありません",
  "scene.image.dialog_message1": "お試し画像が生成されました",
  "scene.image.dialog_error_message": "不明なエラーが発生しました。しばらくしてから再度お試しください。",
  "scene.image.trial.button": "お試し画像スイッチ",
  "scene.image.view_image": "お試し画像を見る",
  "scene.image.use_point": "消費ポイント",
  "scene.image.switch_button": "でこの子をswitchする",
  "scene.image.switched_button": "Switch済み",
  "character.created": "キャラクターが作成されました",
  "character_list.recommend_character": "おすすめキャラクター",
  "character_list.my_character": "マイキャラクター",
  "character_list.character_create":"キャラクターを作成",
  "current_plan.current_plan": "現在のプラン",
  "current_plan.plan": "プラン",
  "current_plan.character": "マイキャラクター",
  "current_plan.character_description": "（現在の数 / 上限）",
  "current_plan.view": "閲覧",
  "current_plan.favorite": "お気に入り",
  "current_plan.favorite_description": "（現在の数 / 上限）",
  "current_plan.switch_point": "Switchポイント",
  "current_plan.switch_point_description": "（サブスク購入+追加購入）",
  "current_plan.change_plan": "変更",
  "current_plan.downgrade_plan": "無料プランへダウングレード",
  "current_plan.change_plan_success": "無料プランへの変更を完了しました",
  "current_plan.change_plan_failed": "無料プランへの変更が失敗しました",
  "character_delete.title": "キャラクターを削除",
  "character_delete.message": "削除するとこのキャラクターの以下の情報が全て失われます",
  "character_delete.detail1": "Switchした動画",
  "character_delete.detail2": "登録したキャラクター画像",
  "character_delete.detail3": "お気に入りにある動画",
  "character_delete.cancel": "キャンセル",
  "character_delete.delete": "削除",
  "character_swap.title": "Switchするキャラクターを選択",
  "character_swap.lack_point": "ポイント不足です",
  "character_swap.message1": "動画のSwitchにはSwitchポイントを消費します。Switchしますか？",
  "character_swap.message2": "保有ポイント",
  "character_swap.message3": "消費ポイント",
  "character_swap.downgrade.message1": "再度、有料プランに契約すると、無効化されたキャラクターの動画を見たりSwitchすることができます。",
  "character_swap.one_time_item.text": "今すぐSwitch！",
  "character_swap.upgrade": "アップグレードしてSwitchする",
  "character_swap.upgrade.text": "より多くの動画でSwitch！",
  "character_swap.tutorial_generate": "Switchする",
  "character_swap.generate": "ポイントを使ってSwitchする",
  "character_swap.button_comment": "※動画/顔が公開されることはありません",
  "character_swap.buy_item": "ポイントを購入する",
  "chracter_new_dialog.description": "まずは入れ替える顔画像を登録しよう！",
  "chracter_new_dialog.button": "顔画像を登録する",
  "confirm_dialog.generating": "Switch中...",
  "confirm_dialog.create": "作成する",
  "confirm_dialog.cancel": "キャンセル",
  "delete_dialog.cancel": "キャンセル",
  "delete_dialog.generating": "削除中...",
  "info_dialog.ok": "OK",
  "plan_downgrade.title": "無料プランへ変更すると",
  "plan_downgrade.mes_01-1": "残りの有効期限",
  "plan_downgrade.mes_01-2": "までご利用いただけます。",
  "plan_downgrade.mes_02": "有効期限が終了すると、キャラクターの数が1つに制限され（1番目のキャラクター）、生成された動画のお気に入りの数も0になります。",
  "plan_downgrade.mes_03": "無効化されたキャラクターで生成された動画は見ることができません。",
  "plan_downgrade.mes_04": "残りのSwitchポイントは使用できなくなります。",
  "plan_downgrade.mes_05": "再度、有料プランに契約すると、無効化されたキャラクターの動画を見たりSwitchすることができます。",
  "plan_downgrade.tell_reason": "ダウングレードする理由をお教えください。",
  "plan_downgrade.reason": "理由",
  "plan_downgrade.please_enter": "入力してください",
  "plan_downgrade.cancel": "キャンセル",
  "plan_downgrade.yes": "変更する",
  "plan_dialog.play": "再生",
  "plan_dialog.switch": "Switch",
  "plan_dialog.character": "キャラクター",
  "plan_dialog.favorite": "お気に入り",
  "plan_dialog.limit": "上限数に達しました",
  "plan_dialog.upgrade": "プランをアップグレード",
  "plan_dialog.close": "閉じる",
  "registration_dialog.message": "FACESWITCHをご利用するにはユーザー登録が必要です。",
  "registration_dialog.registration": "ユーザー登録する",
  "registration_dialog.close": "閉じる",
  "feed.input_name": "ネームを入力",
  "age_confirm.title": "年齢確認",
  "age_confirm.age_confirm": "あなたは18歳以上ですか？",
  "age_confirm.message1": "ここから先は、アダルト商品を扱うアダルトサイトとなります。",
  "age_confirm.message2": "18歳未満の方のアクセスは固くお断りします。",
  "age_confirm.yes": "はい",
  "age_confirm.no": "いいえ",
  "character.edit.update_complete": "更新を完了しました",
  "character.edit.error": "エラー",
  "character.edit.delete_complete": "削除を完了しました",
  "character.edit.title": "キャラクターを編集",
  "character.edit.input_name": "ネームを入力",
  "character.edit.complete": "完了",
  "character.edit.delete_character": "キャラクターを削除する",
  "character.edit.cancel": "キャンセル",
  "character.index.title": "キャラクター管理",
  "character.index.free_plan": "フリープラン",
  "character.index.my_character": "マイキャラクター",
  "character.index.new_character_create": "新しいキャラクターを作成",
  "character.new.title": "キャラクター作成",
  "character.new.error_face_not_found": "顔が見つかりません。違う画像でもう一度お試しください。",
  "character.new.message1": "キャラクターの画像を追加します",
  "character.new.upload": "画像アップロード",
  "character.new.white": "美白",
  "character.new.skin": "美肌",
  "character.new.face": "小顔",
  "character.new.eye": "目",
  "character.new.nose": "鼻",
  "character.new.rip": "唇",
  "character.new.input_name": "ネームを入力",
  "character.new.generating": "作成中...",
  "character.new.add_tag": "タグを付ける（任意）",
  "character.new.create_character": "キャラクターを作成する",
  "character.new.create_character_comment": "※アップした画像が公開されることはありません。",
  "character.new.cancel": "キャンセル",
  "character.new.error": "エラー",
  "character.new.ok": "OK",
  "character.view.scene_upload": "動画をアップロード",
  "character.view.not_found": `Switchした動画がありません。
ホームに戻ってSwitchしましょう。`,
  "contact.title": "お問い合わせ",
  "contact.name": "名前",
  "contact.mail": "メールアドレス",
  "contact.content": "お問い合わせ内容",
  "contact.submit": "送信",
  "contact.send_success": "お問い合わせを送信しました",
  "contact.send_failed": "エラー：送信に失敗しました",
  "faq.title": "よくある質問",
  "faq.q_1": "未成年者が申し込むことはできるか確認したい",
  "faq.q_2": "FACESWITCHのアカウントとは",
  "faq.q_3": "利用中サービス（プラン）の確認がしたい",
  "faq.q_4": "購入履歴の確認がしたい",
  "faq.q_5": "ログインID／パスワードを忘れた",
  "faq.q_6": "ログインID／パスワードを変更したい",
  "faq.q_7": "入力されたメールアドレスは登録されていませんと表示される",
  "faq.q_8": "メールが届かない",
  "faq.q_9": "メールが届かない（携帯電話会社のメールで受信できない）",
  "faq.q_10": "解約（無料プランへダウングレード）する方法",
  "faq.q_11": "ポイントとは",
  "faq.q_12": "料金について",
  "faq.q_13": "月額プランの料金",
  "faq.q_14": "利用可能な支払い方法",
  "faq.q_15": "有料商品の課金を取り消したい",
  "faq.q_16": "ポイントに有効期限はありますか",
  "faq.a_1": `未成年者が、その親権者や未成年後見人の同意なしに会員登録を行うことはできません。

利用規約にて規定されており、会員登録時に規約へ同意をいただく必要があります。
  
※申込をもって規約へ同意いただいたものとし、親権者または未成年後見人の同意のお申込と判断いたします。`,
  "faq.a_2": `■アカウントとは
・FACESWITCHのサービスをお使いいただくため、お客様にご登録いただいた認証情報です。
  
■認証情報とは
・ログインID／パスワードのことです。
  
■アカウントに関連付けられる情報
＜ご利用中の各種月額サービスの有無＞
・ご契約中のサービスがあると関連付けられます。
・関連付いたサービスはご利用いただけます。

  
＝月額サービスの例＝
  
★月額プラン
  
＜有料作品の視聴権利＞
・様々な有料作品の視聴権利や期限が関連付けられます。
  
＜有料コンテンツ＞
・キャラクター作成
・画像生成
・動画閲覧回数
・お気に入り登録数
  
  
＜お客様情報＞
・ご登録いただく各種お客様情報が関連付けられます。
  
＝お客様情報の例＝
  
★お名前
★メールアドレス
★セキュリティコード
★お支払いに関する情報など
  
  
■アカウントの活用方法
・月額サービスを全て解約したとしても、アカウントのみで継続できます。
  
＝アカウントのみでもできること＝
  
★無料動画の閲覧
  
・そして、ご希望に合わせて月額サービスを再開することができます。
  
  
※契約中の月額サービスが存在すると、アカウントが削除できないのはこのためです。`,
  "faq.a_3": `■確認方法
1：FACESWITCHのwebサイトにログイン。
2：メニューから［マイページ］を選択。`,
  "faq.a_4": `1：メニューの［マイページ］を選択。
2：［購入履歴］を選択。
3：購入履歴が表示される。`,
  "faq.a_5": `■はじめに
＜文字形式の誤り＞
・入力に誤りがないか確認してください。
  
ログインID/パスワードの入力時には半角英数字での入力が必要です。
  
※大文字と小文字は別の文字として識別されます。
  
＜端末が記憶したパスワードの更新漏れ＞
・パスワード変更前の情報がブラウザ等へ記憶されており、自動入力を使用することでログインに失敗するケースが多くあります。
  
※自動入力は使わずにお試しください。
  
■解決できない場合
・以下の方法でログインIDの確認と、パスワードの再設定ができます。
  
  
■確認・再設定の方法
1：ログイン画面を表示する。
2：［パスワードを忘れた方はこちら］を選択。
3：専用フォームが表示される。
4：登録しているメールアドレスを入力する。
5：［リセットメールを送信］を選択。
6：4で入力したメールアドレスへメールが届く。
7：メール内容で手順を確認する。
8：ログインIDの確認、パスワードを再設定する。
  
再設定した情報でログインしてください。
  
※@face-switch-ai.com のドメインから送信します。
※「入力されたメールアドレスは登録されていません」と表示される場合はこちら。
  
■URLの期限切れで再設定できない
・確認・再設定の方法の 1 から再度メールを送信してください。
  
■メールが届かず再設定できない
・迷惑メールフォルダに届いていないかご確認ください。
  
  
■メールアドレスが使えず再設定できない
★登録したメールアドレスを忘れた
★登録したメールアドレスが使えなくなった
  
・これらのケースに該当する場合はお問い合わせください。`,
  "faq.a_6": `「ログインID／パスワードを忘れた」をご覧ください`,
  "faq.a_7": `ログインID／パスワードの確認・変更を行う際の本人確認メールは、ご登録のメールアドレスへ送信してください。`,
  "faq.a_8": `Gmailなどのメールアドレスを登録されたお客様向けのページです。



■メールが届かない原因
・以下の問題で受信ができないことがあります。
  
＜迷惑メール対策＞
・迷惑メールフォルダへ自動分類された。
  
＜容量オーバー＞
・メールの最大受信容量を超えている。
  
＜設定値＞
・メールソフトに設定した値に誤りがある。
  
※パスワード変更後の更新漏れも含む。
  
  
  
■対処方法
＜フォルダ確認＞
・迷惑メールフォルダ内含め全フォルダの受信状況をご確認ください。
  
※例えばGmailの場合、迷惑メールフォルダを表示させるには操作が必要です。
  
  
  
※ご利用のメールアプリケーションの仕様は提供元へご確認ください。
  
＜データ容量確認＞
・Gmailを含め、受信したメールを保管できる容量には上限があります。
  
※上限を超えた場合は、メールが受信できなくなるので、不要データの削除が必要です。
※Gmailに関する説明はこちら。
  
■ドメイン指定する
・以下のドメインを受信許可リストへ登録してください。
  
■設定するドメイン名
@face-switch-ai.com`,
  "faq.a_9": `■メールが届かない原因
  ・迷惑メール対策で以下の設定をしている場合、メールが届かないことがあります。
  
  ＜ドメイン設定＞
  ・特定ドメインのメールのみ受信を許可している。
  
  ＜パソコン拒否設定＞
  ・パソコンからのメール受信を拒否している。
  
  ＜セキュリティー設定＞
  ・携帯電話会社提供の迷惑メール対策の強度を上げている。
  
  ■対処方法
  ・以下のドメインを受信許可リストへ登録してください。
  
  ■設定するドメイン名
  @face-switch-ai.com
  
  
  ■URL付きメール拒否設定を解除
  ・URL付きメール拒否設定を解除してください。`,
  "faq.a_10": `1：メニューの［マイページ］を選択。
2：プランの横の［変更］を選択。
3：「無料プランへダウングレード」を選択
4：注意事項を確認し、「はい」を選択`,
  "faq.a_11": `- ポイントとは
ポイントを購入することでSwitchに使用出来るポイントを手に入れることができます。
  
- ポイントをチャージする
マイページ > ポイントを購入する
からご希望のプランを選択して購入してください。
  
- 現在の保有ポイント数
マイページ > 現在のプラン > Switchポイント から確認ができます。
  
- ポイントの購入履歴
マイページ ＞ 購入履歴 から確認できます。`,
  "faq.a_12": `月額プラン

<月額利用料>
$15（税込）です。
  
  
有料商品
  
例
- キャラクター作成
- Switch/月
- 動画閲覧/月
- お気に入り`,
  "faq.a_13": `月額プラン

<月額利用料>
$15（税込）です。`,
  "faq.a_14": `■クレジットカード
・以下のブランドがご利用いただけます。
・全ての料金の支払いが可能です。
  
★ VISA
* Master
* JCB`,
  "faq.a_15": `ポイントチャージなどの有料商品は課金後の取り消しはできません。`,
  "faq.a_16": `追加購入したポイントは購入日から6ヶ月間有効です。`,
  "law.title": "特商法に基づく表記",
  "help.title": "ヘルプ",
  "term.title": "利用規約",
  "terms.title": "利用規約",
  "policy.title": "プライバシーポリシー",
  "demo.title": "FACESWITCH",
  "demo.free_demo": "無料デモ",
  "demo.lets_text1": "あなたの好きな顔画像で",
  "demo.lets_text2": "オリジナルのAVを作ろう",
  "demo.step1_text": "顔を選択",
  "demo.step2_text": "動画を選択",
  "demo.result": "Switch結果",
  "demo.display_result": "ここにSwitch動画が表示されます",
  "demo.easy": `カンタン
30秒`,
  "demo.button1": "FACESWITCHでできること",
  "demo.button2": "30秒でわかる使い方動画",
  "demo.movielist": "動画一例",
  "demo.upgrade": "アップグレードしてSwitchする",
  "demo.register": "無料でSwitchしてみる",
  "demo.login": "ログインはこちら",
  "demo.scene_title": "動画閲覧",
  "demo.scene_description": "無制限",
  "demo.max_character": "キャラクター数上限",
  "demo.free_plan_max_character_num": "1人",
  "demo.subscription_max_character_num": "20人",
  "demo.switch_title": "顔スイッチ動画",
  "demo.plan.switch_description1": "2000ポイント",
  "demo.plan.switch_description2": "(25分相当)/月",
  "demo.switch_description": "25分相当/月",
  "demo.favorite_title": "お気に入り追加",
  "demo.favorite_description": "95本",
  "demo.parallel_switch_title": "同時スイッチ可能本数",
  "demo.parallel_switch_description": "2本",
  "demo.av_title": "フルAV視聴",
  "demo.av_description": "無制限",
  "demo.modal_description": "FACESWITCHはあなたの好きな顔画像で、顔をスイッチして楽しむ動画生成サービスです。",
  "demo.modal_free": "無料会員でできること",
  "demo.modal_not_free": "有料会員でできること",
  "demo.modal_button_up":"無料会員登録をする",
  "demo.modal_button": "ユーザー登録へ進む（無料）",
  "demo2.description.text1.a": '好きな顔',
  "demo2.description.text1.b": '画像にスイッチして楽しむ',
  "demo2.description.text1.c": '動画生成',
  "demo2.description.text1.d": 'サービス',
  "demo2.description.text2": 'FACESWITCHは、あなたの好きな顔画像で、顔をスイッチして楽しむ動画生成サービスです。',
  "demo2.description.text3.a": '手持ちの写真',
  "demo2.description.text3.b": 'で自分だけの',
  "demo2.description.text3.c": 'オリジナルAV',
  "demo2.description.text3.d": 'を作ろう',
  "demo2.description.text4": 'あなたの好きな顔画像をアップロードし、数ある動画の中から好みの動画を選択して、自分だけのオリジナルAVを作成できます。',
  "demo2.description.text5.a": 'オリジナルAVを作成するための',
  "demo2.description.text5.b": '3つ',
  "demo2.description.text5.c": 'の簡単な',
  "demo2.description.text5.d": '手順',
  "demo2.description.text6": '動画一例',
  "demo2.description.text7": '新作動画ぞくぞく追加予定！',
  "demo2.description.text8": '安心・安全な理由',
  "demo2.description.text8-1.title": '法令を遵守した運営を行っています',
  "demo2.description.text8-1.description": '法律に準拠したサービス運営を行い、第三者へのデータ提供や不正利用の心配は一切ありませんのでご安心ください。',
  "demo2.description.text8-2.title": '作成した動画や顔写真は公開されません',
  "demo2.description.text8-2.description": 'アップロードした顔写真や作成した動画が公開されることはありません。安心してサービスをご利用ください。',
  "demo2.description.text8-3.title": '個人利用なら法的に何も問題はありません',
  "demo2.description.text8-3.description": '個人利用の範囲でなら、このサービスを利用することは法的に問題ございません。安心してお楽しみください。',
  "home.title": "FACESWITCH",
  "home.recommend": "動画をSwitch",
  "home.favorite": "お気に入り",
  "home.empty_favorite_free": "有料プランにすると、お気に入り機能が使用できます。",
  "home.empty_favorite": "お気に入りが登録されていません。",
  "home.empty_favorite2": "登録するにはSwitchした動画が必要です。",
  "home.switched": "Switch済",
  "home.empty_switched": "Switchした動画がまだありません。",
  "home.empty_switched_image": "Switchした画像がまだありません。",
  "home.load_more": "さらに読み込む",
  "home.switch_trial_text": "付与された100ポイント(動画約1本分)で、動画のSwitchを無料でお試しいただけます。有料プランだと、より多くの動画でSwitchが可能です。",
  "home.upgrade_banner.button": "アップグレードする",
  "home.upgrade_banner.text": "次画面以降で決済確認が完了するまで支払いは行われません",
  "home.bottom_upgrade_banner.text_01": "{num}作品以上！",
  "home.bottom_upgrade_banner.text_02": "毎週新作追加中！",
  "home.bottom_upgrade_banner.description": "もっとたくさんの動画でSwitchを楽しもう！",
  "home.bottom_upgrade_banner.button": "アップグレードしてSwichする",
  "home.onetime_item.button": "今すぐ購入する",
  "home.timesale.button": "キャンペーンの詳細を確認する",
  "mypage.title": "マイページ",
  "mypage.user_info": "ユーザー情報",
  "mypage.mail": "メールアドレス",
  "mypage.register_mail": "メールアドレスを登録する",
  "mypage.register_mail.text": "※ポイントを購入する際に必要です。",
  "mypage.register_mail.success": "メールアドレス認証が完了しました。",
  "mypage.register_mail.attention": "決済にはメールアドレス登録が必要になります。マイページから登録をお願いします。",
  "mypage.password": "パスワード",
  "mypage.password.none": "パスワード(未登録)",
  "mypage.password.attention": "※パスワードを設定するとメールアドレスでログインできます。",
  "mypage.password.attention.no_mail": "※メールアドレスを先に登録してください",
  "mypage.other_info": "その他の情報",
  "mypage.current_plan": "現在のプラン",
  "mypage.purchase_history": "購入履歴",
  "mypage.plan_and_point": "プランとポイント",
  "mypage.sample_scene.before": "有料会員になると、次からの動画もSwitch可能です。",
  "mypage.sample_scene": "他にも様々な動画でのスイッチが可能",
  "mypage.law": "特商法に基づく表記",
  "videos.title": "見放題動画",
  "videos.caption1": "有料プランの見放題動画",
  "videos.loading": "読み込み中...",
  "videos.free_description": "有料プランにすると、無制限でフルAV動画を視聴できます。",
  "videos.free_button": "アップグレードして動画を見る",
  "scene.enjoy": "この動画で顔をSwitch！",
  "scene_image.enjoy": "この顔で動画Switch！",
  "scene.add.confirm1": "動画を作成します。",
  "scene.add.confirm2": "動画作成後はタイトル、キャプションのみ編集が可能です。",
  "scene.add.warning1": "顔をSwitchしないをオンにすると、",
  "scene.add.warning2": "キャラクターが公開設定になっているとアップロードした画像・動画のまま他者からも見れるようになります。",
  "scene.add.warning3": "アップロード画像・動画に関してはコンテンツガイドラインを確認してください。",
  "scene.add.scene_create": "動画を作成",
  "scene.add.scene_title": "動画タイトル",
  "scene.add.add_movie": "動画を追加",
  "scene.add.create": "作成する",
  "scene.add.generate": "Switchする",
  "scene.add.cancel": "キャンセル",
  "scene.edit.delete_message": "このキャラクターを削除しますか?",
  "scene.edit.edit_scene": "動画を編集",
  "scene.edit.scene_title": "動画タイトル",
  "scene.edit.generating": "Switch中...",
  "scene.edit.save": "保存する",
  "scene.edit.delete_scene": "動画を削除する",
  "scene.edit.cancel": "キャンセル",
  "scene.feedback.ask.title": `スイッチした動画の
感想を聞かせてください！`,
  "scene.feedback.ask.description": "今後の品質向上の参考とさせていただきます。",
  "scene.feedback.good.title": `ご満足いただけて嬉しいです🎉\nもっとスイッチを楽しみたい方への初回キリの限定オファー！`,
  "scene.feedback.good.minutes": "ポイント（{minutes}分相当）",
  "scene.feedback.good.subtitle": `もっと他の動画やキャラクターで\nスイッチしたい方はBronzeプランも`,
  "scene.feedback.good.plan_button": "プランの内容を確認する",
  "scene.feedback.bad.title": `ご期待に添えず
申し訳ございません`,
  "scene.feedback.bad.present1": "無料の動画Switchを",
  "scene.feedback.bad.present2": "1回分プレゼント",
  "scene.feedback.bad.present3": "したので",
  "scene.feedback.bad.present4": "ぜひもう一度お試しください！",
  "scene.feedback.bad.tip_title": "うまくSwitchするためのコツ",
  "scene.feedback.bad.tip_description1": "顔立ちのはっきりしたキャラクターを選ぶ",
  "scene.tag.new": "新作",
  "scene.tag.semi_new": "準新作",
  "scene.tag.weekly_rank": "週間ランキング{num}位",
  "scene.tag.overall_rank": "累計ランキング{num}位",
  "scene.tag.fellatio": "フェラ",
  "user.login.title": "ログイン",
  "user.login.already_login1": "すでにログインしています",
  "user.login.already_login2": "リダイレクトする",
  "user.login.input_login_info": "ログイン情報を入力してください",
  "user.login.login_id": "ログインID (メールアドレス)",
  "user.login.password": "パスワード",
  "user.login.password_label": "パスワード",
  "user.login.forget_password1": "パスワードを忘れた方は",
  "user.login.forget_password2": "こちら",
  "user.login.login": "ログイン",
  "user.login.signup": "新規登録",
  "user.login.signup_with_google": "Googleで登録",
  "user.login.signup_with_x": "Xで登録",
  "user.login.signup_with_line": "LINEで登録",
  "user.login.already_exist": "既にメールアドレスが存在します。",
  "user.login.google": "Google",
  "user.login.x": "X",
  "user.login.line": "LINE",
  "user.login.login_failed": "メールアドレスまたはパスワードが違います",
  "user.login.login_failed_google": "Googleアカウントユーザーが存在しません。",
  "user.login.login_failed_x": "Xアカウントユーザーが存在しません。",
  "user.login.login_failed_line": "LINEアカウントユーザーが存在しません。",
  "user.login.resend_password_mail": "パスワード再設定メールを送信しました",
  "user.login.password_change_done": "パスワードを変更しました",
  "user.email_registration.title": "メールアドレスの登録",
  "user.email_registration.description": "メールアドレスを入力してください。",
  "user.email_registration.mail": "メールアドレス",
  "user.email_registration.submit": "メールアドレスを登録する",
  "user.email_registration.send": "認証メールを送信しましたので、メールに記載の URL から登録を完了してください。 メールが届かない場合は、迷惑メールフォルダもご確認ください。",
  "user.email_registration.submit_message": "メールを送信しました。",
  "user.password_recovery.length_error": "パスワードは8文字以上で入力してください",
  "user.password_recovery.invalid_password": "パスワードが一致しません",
  "user.password_recovery.invlaid_token": "Invalid token",
  "user.password_recovery.expired": "URLの有効期限が切れています。再度パスワードリセットを行ってください。",
  "user.password_recovery.title": "パスワード変更",
  "user.password_recovery.description": "登録するパスワードを入力してください。",
  "user.password_recovery.password_label1": "登録するパスワード",
  "user.password_recovery.password_label2": "登録するパスワード（再入力）",
  "user.password_recovery.save": "上記内容で保存",
  "user.password_reset.title": "パスワード変更",
  "user.password_reset.description": "登録したメールアドレスを入力してください。パスワードを設定するリンクを送信します",
  "user.password_reset.mail": "メールアドレス",
  "user.password_reset.submit": "リセットメールを送信",
  "user.info.update.title": "ユーザー情報変更",
  "user.info.update.success": "ユーザー情報の更新が完了しました",
  "user.info.update.failed": "ユーザー情報の更新に失敗しました",
  "user.info.name": "お名前（ローマ字フルネーム）",
  "user.info.name.invalid": "お名前は半角英字で入力してください",
  "user.info.phone": "電話番号",
  "user.info.input.dialog_description.item": "Switchポイントを{amount}ドル以上ご購入の場合は、お名前と電話番号の登録が必要です。",
  "user.info.input.dialog_description.plan": "{plan}プランをご購入の場合は、お名前と電話番号の登録が必要です。",
  "user.purchase.history.title": "購入履歴",
  "user.purchase.history.empty": "購入履歴はありません",
  "user.plan.about_switched_scene_streaming.title": "Switchした動画の再生について",
  "user.plan.about_switched_scene_streaming.description1": "Switch後{bold1}すると、お気に入り以外の動画はアーカイブされ、{bold2}がかかります。",
  "user.plan.about_switched_scene_streaming.description1.bold1": "45日以上経過",
  "user.plan.about_switched_scene_streaming.description1.bold2": "再視聴にロード時間",
  "user.plan.about_switched_scene_streaming.description2": "Switchした動画を{bold1}すると、{bold2}視聴できます。",
  "user.plan.about_switched_scene_streaming.description2.bold1": "お気に入りに追加",
  "user.plan.about_switched_scene_streaming.description2.bold2": "いつまでも素早く",
  "user.plan.comparison": "プラン比較",
  "user.plan.title": "プラン",
  "user.plan.per_month": "/月",
  "user.plan.plan_list": "ご利用できるプラン（月額）",
  "user.plan.feature": "使える機能",
  "user.plan.character.label": "顔アップロード",
  "user.plan.character.unit": "キャラ",
  "user.plan.swap.label": "Switchポイント/月",
  "user.plan.swap.unit": "ポイント",
  "user.plan.swap.unit_after": "（25分相当）",
  "user.plan.swap.unit_after_non_num": "分相当",
  "user.plan.item.buy_normal_button": "で購入する",
  "user.plan.item.buy_limited_button": "{point}ポイントを購入（お一人限定{limit}回）",
  "user.plan.item.can_buy_limited_point": "ご購入できるポイント",
  "user.plan.item.can_buy_additional_point": "追加でご購入できるポイント",
  "user.plan.item.count": "回",
  "user.plan.item.limit": "限定",
  "user.plan.av.label": "フルAV視聴",
  "user.plan.av.free": "視聴不可",
  "user.plan.av.paid": "無制限",
  "user.plan.view.label": "動画閲覧/月",
  "user.plan.view.unit": "本",
  "user.plan.favorite.label": "お気に入り追加",
  "user.plan.favorite.unit": "本",
  "user.plan.parallel_switch.label": "同時スイッチ可能本数",
  "user.plan.parallel_switch.unit": "本",
  "user.plan.plan_suffix": "プラン",
  // "user.plan.buy_plan": "プランを購入",
  "user.plan.buy_plan": "プランを1ヶ月試す",
  "user.plan_complete.title": "購入完了",
  "user.plan_complete.home": "さっそくSwitchする",
  "user.plan_complete.mypage": "マイページ",
  "user.plan_failed.title": "購入失敗",
  "user.plan_failed.home": "ホームへ",
  "user.plan_failed.mypage": "マイページ",
  "user.plan.free.bronze_can": "ブロンズプランでできること",
  "user.plan.free.point_title": "3つの安心ポイント",
  "user.plan.free.point1_text1": "マルチデバイス対応",
  "user.plan.free.point1_text2": "PCでもスマホでも楽しめます！",
  "user.plan.free.point2_text1": "月末入会でもOK",
  "user.plan.free.point2_text2": "支払い日から1ヶ月間使えるのでいつでも入会できます！",
  "user.plan.free.point3_text1": "解約しても安心",
  "user.plan.free.point3_text2": "解約手続き後も更新日 までは楽しめます",
  "user.plan.free.announce": "重要なお知らせ",
  "user.plan.free.announce_description": `※本サービスは入金日を基準にし、1ヶ月単位で毎月自動更新がおこなわれます\n
  ※毎月の自動更新を希望されない場合は、有効期限内に解約手続きを行なってください\n
  ※ご購入前に無料サンプルをご確認ください`,
  "user.plan.free.caution": "注意",
  "user.plan.free.caution_description": `※Bronzeプランを継続する場合、1ヶ月後に再度決済画面で決済する必要がございます。あらかじめご了承ください。`,
  "user.plan.common.button_caption": "次画面以降で決済確認が完了するまで支払いは行われません",
  // "user.plan.bronze.downgraded": "無料プランへ変更申請済み",
  "user.plan.bronze.downgraded": "次の更新タイミングで自動解約になります",
  "user.plan.bronze.downgrade.info": "有効期限が終了すると、無料プランに自動で変更されます。",
  "user.plan.bronze.limit_time": "有効期限",
  "user.plan.bronze.day": "日",
  "user.signup.already_signup": "登録に失敗しました。",
  "user.signup.success": "認証が完了しました。1秒後にユーザーに移動します。",
  "user.signup.social.already_exist": "このSNSで既にユーザー登録があります。ログインしてください。",
  "user.signup.social.error": "ユーザー登録中にエラーが発生しました。",
  "user.signup.success_message": `認証メールを送信しましたので、メールに記載の URL から登録を完了してください。\n
  \nメールが届かない場合は、迷惑メールフォルダもご確認ください。`,
  "user.signup.title": "ユーザー登録",
  "user.signup.description": "ユーザー登録情報を入力してください。",
  "user.signup.login_id": "ログインID (メールアドレス)",
  "user.signup.password": "パスワード",
  "user.signup.age": "年齢",
  "user.signup.age_10": "18~19歳",
  "user.signup.age_20": "20~29歳",
  "user.signup.age_30": "30~39歳",
  "user.signup.age_40": "40~49歳",
  "user.signup.age_50": "50~59歳",
  "user.signup.age_60": "60~69歳",
  "user.signup.age_70": "70~79歳",
  "user.signup.age_80": "80歳~",
  "user.signup.age_no": "未回答",
  "user.signup.policy": "プライバシーポリシー",
  "user.signup.sep": "と",
  "user.signup.terms": "利用規約",
  "user.signup.policy_agree": "に同意する",
  "user.signup.register": "無料スイッチ体験をする",
  "user.signup.other_account": "他のアカウントで登録",
  "user.signup.to_login": "ログインはこちら",
  "user.signup_plan.title": "プラン",
  "user.signup_plan.description1": "ユーザー登録が完了しました。",
  "user.signup_plan.description2": "プランを購入するにはクレジットカードの登録が必要です。",
  "user.signup_plan.regist_cregit": "クレジットカードを登録する",
  "user.signup_plan.next": "無料ユーザーのまま進む",
  "common.invalid.email_format": "無効なメール形式です",
  "common.invalid.email_alias": "メールアドレスのエイリアスは使用できません",
  "common.invalid.email": "メールアドレスが無効です",
  "common.register.failed": "登録に失敗しました。",
  "common.name.required": "名前は必須項目です",
  "common.content.required": "お問い合わせ内容は必須項目です",
  "common.password.invalid_length": "パスワードは8文字以上です",
  "common.token.expired": "tokenの期限が切れています",
  "common.token.invalid": "不正なtokenです",
  "generate.in_queue": "待機中",
  "generate.in_progress": "Switch中",
  "generate.in_uploading": "出力中",
  "generate.failed": "失敗しました",
  "generate.complete": "動画が作成されました。Switchタブから見ることができます。",
  "generate.error.success": "Switchを開始しました",
  "generate.error.ongoing": "このSwitchはすでに進行中です",
  "generate.error.regenerating": "前回のSwitchを再実行中です",
  "generate.error.already_exists": "既にSwitchされている動画です",
  "generate.error.over_parallel_limit": "Switch上限数に達しました",
  "generate.error.unknown": "不明なエラーが発生しました。しばらくしてから再度お試しください。",
  "login.timeout": "タイムアウトしました。 ログイン画面にリダイレクトします。",
  "login.loading": "Loading...",
  "login.not_login": "ログインが必要です",
  "video_stream.upgrade.required": "閲覧には、有料プランの購入が必要です。",
  "video_stream.upgrade.button": "アップグレードする",
  "category.play": "プレイ",
  "category.female_category": "タイプ",
  "category.genre": "ジャンル",
  "category.situation": "シチュエーション",
  "category.hairstyle": "髪型",
  "category.publish_date": "配信開始日",
  "category.ranking": "ランキング",
  "search.recommend": "おすすめ順",
  "search.latest": "新着順",
  "search.popularity": "人気順",
  "guest.scene.subtitle": "動画一覧",
  "guest.scene.dialog.description": "利用するにはユーザー登録が必要です",
  "guest.scene.dialog.signup": "新規会員登録する（無料）",
  "maintenance.title": "メンテナンス中",
  "maintenance.description": "現在メンテナンス中です。ご不便をおかけして申し訳ございません。後ほど再度アクセスしてください。",
  "maintenance.description_long": "メンテナンスに想定以上の時間を要しており、後ほど再度アクセスをお願いいたします。復旧完了しましたらメルマガにてお知らせいたします。ご不便をおかけして申し訳ございません。",
  "help.link_term": "利用規約はこちら",
  "timesale.countdown.title": "タイムセール終了まであと",
}
